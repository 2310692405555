import React, { useEffect, useRef, useState } from "react";
import DesktopSidebar from "./componants/digisign/DesktopSidebar";
import MobileBottomNav from "./componants/digisign/MobileBottomNav";
import useWindowSize from "./componants/digisign/useWindowSize";
import Headers from "../../pages/Header";
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import axios from 'axios'
import useStateRef from "react-usestateref";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useNavigate, Link } from "react-router-dom";
import html2canvas from 'html2canvas'; // Importing html2canvas
import jsPDF from 'jspdf'; // Importing jsPDF
import { Rnd } from 'react-rnd';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.min.js';

function NewLayout() {

  const apiurl = "http://localhost:5001/"
  // const apiurl = "https://market.lawblocks.io:5001/"
  const { width } = useWindowSize();

  const fonts = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'];

  const [selectedFont, setSelectedFont] = useState(fonts[0]);

  const handleFontChange = (event) => {
    setSelectedFont(event.target.value);
  };


  const cancel = async (value) => {
    setfile(false)
    const pdfViewerElement = pdfViewerRef.current;
    const canvas = await html2canvas(pdfViewerElement);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', 'a4');

    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (imgWidth * 841) / 595; 
    pdf.addImage(value.file, 'PNG', 0, 0, imgWidth, imgHeight);
    pdf.save(`sign_${value.fileName}`);
  }

  const fileInputRef = useRef(null);

  const [pdfFile, setPdfFile, pdfFileref] = useStateRef(null);
  const [signatures, setSignatures] = useState([]);
  const [signatureMode, setSignatureMode] = useState('');
  const [typedSignature, setTypedSignature] = useState('');

  // ==============================file Upload===========================/

  const navigate = useNavigate();
  const [File, setFile, Fileref] = useStateRef("");
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [checked, setchecked, checkedref] = useStateRef(false);

 
  const [userDetails1, setuserDetails1] = useState({})
  const [orgfile, setorgfile] = useState({})
  const [_id, set_id] = useState("")

  useEffect(() => {

    var url = window.location.href.split('?')[1]
    if (url != undefined) {

      const getpdf = async () => {
       try {
        const response = await axios.post(apiurl + 'api/getuser', { id: url });
        if (response.data.status == true) {
          const storedPdfData = response.data.data.file;
          console.log(response.data.data)
          const pdf = new jsPDF();
          pdf.addImage(storedPdfData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
          const pdfBlob = pdf.output('blob');
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setElements([])
          setfile(true)
          set_id(response.data.data._id)
          setPdfFile(pdfUrl);  // Update the state with the newly created PDF URL
        }
       } catch (error) {
        console.log(error)
       }
      }
      getpdf()

    }

    const getuser = async () => {
      const response = await axios.get(apiurl + 'api/getuser');
      if (response.data.status == true) {
        setuserDetails1(response.data.data)
      } else {
        setuserDetails1(response.data.data)
      }
    }
    getuser()
  }, [0])

  const checkbox = (e) => {
    setchecked(e.target.checked)
  }




  const [elements, setElements] = useState([]);
  const [isSigning, setIsSigning] = useState(false);
  const [loader, setloader] = useState(false);
  const [isAddingText, setIsAddingText] = useState(false);
  const [uploadedSignature, setUploadedSignature] = useState(null);
  const [file, setfile] = useState(false)

  const signatureRef = useRef({});
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const pdfViewerRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);

    if (file && file.type === 'application/pdf') {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1]; // Extract the Base64 part
        setorgfile(reader.result)
      };
      reader.readAsDataURL(file);
      setPdfFile(URL.createObjectURL(file));
    } else {
      alert('Please select a PDF file.');
    }
  };

  const saveSignature = () => {
    const signatureImage = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    setElements([...elements, { type: 'signature', src: signatureImage, x: 50, y: 50, width: 200, height: 100 }]);
    setIsSigning(false);
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveUploadedSignature = () => {
    setElements([...elements, { type: 'signature', src: uploadedSignature, x: 50, y: 50, width: 200, height: 100 }]);
    setUploadedSignature(null);
  };

  const addText = () => {
    setElements([...elements, { type: 'text', text: `${typedSignature}`, x: 50, y: 50, width: 200, height: 50 }]);
    setIsAddingText(false);
  };

  const updateElementPosition = (index, x, y, width, height) => {
    const newElements = [...elements];
    newElements[index] = { ...newElements[index], x, y, width, height };
    setElements(newElements);
  };

  const handleUploadSignature = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setUploadedSignature(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please upload an image file.');
    }
  };

  const renderElements = () => {
    return elements.map((element, index) => (

      <Rnd
        key={index}
        size={{ width: element.width, height: element.height }}
        position={{ x: element.x, y: element.y }}
        onDragStop={(e, d) => updateElementPosition(index, d.x, d.y, element.width, element.height)}
        onResizeStop={(e, direction, ref, delta, position) => {
          updateElementPosition(index, position.x, position.y, parseInt(ref.style.width, 10), parseInt(ref.style.height, 10));
        }}
        style={{ border: '3px solid #000', padding: '4px', borderRadius: '4px', backgroundColor: 'transparent', position: 'relative' }}
      >
        {/* Top-left corner */}
        <div style={{
          width: '10px', height: '10px', backgroundColor: 'black', position: 'absolute',
          top: '-5px', left: '-5px', borderRadius: '50%'
        }} />

        {/* Top-right corner */}
        <div style={{
          width: '10px', height: '10px', backgroundColor: 'black', position: 'absolute',
          top: '-5px', right: '-5px', borderRadius: '50%'
        }} />

        {/* Bottom-left corner */}
        <div style={{
          width: '10px', height: '10px', backgroundColor: 'black', position: 'absolute',
          bottom: '-5px', left: '-5px', borderRadius: '50%'
        }} />

        {/* Bottom-right corner */}
        <div style={{
          width: '10px', height: '10px', backgroundColor: 'black', position: 'absolute',
          bottom: '-5px', right: '-5px', borderRadius: '50%'
        }} />

        {element.type === 'signature' ? (
          <img
            src={element.src}
            alt={`signature-${index}`}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        ) : (
          <div style={{ width: '100%', height: '100%', fontSize: '16px', padding: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {element.text}
          </div>
        )}
      </Rnd>


    ));
  };

  const nextAction = async () => {
    if (checkedref.current == false && Fileref.current) {
      if (Email != "" && name != "") {
        setfile(true)
      } else {
        toast.error('All field required')
      }
    } else {
      if (Fileref.current) {
        setfile(true)
      } else {
        toast.error("Please select file");
      }
    }

  }

  const saveToPDF = async () => {
    try {
      setloader(true);

        const pdfViewerElement = pdfViewerRef.current;
        
        // Adjust scale for higher resolution
        const canvas = await html2canvas(pdfViewerElement, { scale: 2 });
        const imgData = canvas.toDataURL('image/png', 1.0); // Preserve the quality
        
        const formData = new FormData();
        formData.append("pdf", Fileref.current);
        formData.append("id", _id);
        formData.append("file", imgData);
        
        if (_id == "") {
            formData.append("status", 0);
        } else {
            formData.append("status", 1);
        }
        formData.append("email", Email == "" ? userDetails1.email : Email);
        formData.append("name", name == "" ? userDetails1.fullName : name);
        
        
        const response = await axios.post(apiurl + "api/save-signed-pdf", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        
        setloader(false);
        if (response.data.status === true) {
            navigate('/digitalDash');
            toast.success(response.data.Message);
        } else {
            toast.error(response.data.Message);
        }
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
};


  // const saveToPDF = async () => {
  //   try {
  //     const pdfViewerElement = pdfViewerRef.current;
  //     const canvas = await html2canvas(pdfViewerElement);
  //     const imgData = canvas.toDataURL('image/png');
  //     console.log(imgData,)
  //     const formData = new FormData();
  //     formData.append("pdf", Fileref.current);
  //     formData.append("id", _id);
  //     formData.append("file", imgData);
  //     if (_id == "") {
  //       formData.append("status", 0);
  //     } else {
  //       formData.append("status", 1);
  //     }
  //     formData.append("email", Email == "" ? userDetails1.email : Email);
  //     formData.append("name", name == "" ? userDetails1.fullName : name);
  //     setloader(true)
  //     const response = await axios.post(apiurl + "api/save-signed-pdf", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     setloader(false)
  //     if (response.data.status == true) {
  //       navigate('/digitalDash')
  //       toast.success(response.data.Message)
  //     } else {
  //       toast.error(response.data.Message)
  //     }
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };

  return (
    <>
      <Headers />

      {file == false ?
        <>
          <section className="py-10 pt-20">
            <div className="container">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2 p-4">
                  <div className="bg-[#0D0B35] min-h-screen flex items-start justify-center w-full flex-col justify-start items-start">
                    <h2 className="text-left text-white text-3xl font-semibold mb-8">
                      Add Documents
                    </h2>
                    <div className="flex items-center justify-center min-h-screen bg-[#0D0B35]">
                      <label
                        htmlFor="file-upload"
                        className="w-full bg-[#10153D] p-12 rounded-lg border-dashed border-2 border-gray-600 cursor-pointer flex flex-col items-center justify-center text-center upload_section_code"
                      >
                        {Fileref.current == "" ?
                          <>
                            <i className="ri-upload-cloud-line text-white text-4xl mb-4"></i>
                            <span className="text-white text-lg font-medium">
                              Upload Document
                            </span></>
                          : <i class="fa fa-file-pdf-o" style={{ fontSize: "200px" }} aria-hidden="true"></i>}
                        <input id="file-upload" type="file" className="hidden" onChange={handleFileChange} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-4">
                  <h2 className="text-left text-white text-3xl font-semibold mb-8">
                    Add Recipients
                  </h2>
                  <div className="min-h-screen flex items-center justify-center">
                    <div className="dark:bg-jacarta-700 w-full rounded-xl p-8 py-10 space-y-6">
                      <div className="flex items-center space-x-3">
                        <input
                          type="checkbox"
                          id="only-signer"
                          onChange={checkbox}
                          className="h-5 w-5 text-[#6c41d6] bg-transparent border-2 border-[#6c41d6] rounded focus:ring-0"
                        />
                        <label
                          htmlFor="only-signer"
                          className="text-white text-lg font-medium"
                        >
                          I am the only signer
                        </label>
                      </div>
                      {checkedref.current == false ?
                        <>
                          <div className="mb-6">
                            <label
                              htmlFor="item-name"
                              className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                            >
                              Name<span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="item-name"
                              name="name"
                              value={name}
                              onChange={(e) => setname(e.target.value)}
                              className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                              placeholder="Enter Name"
                              required
                            />
                          </div>
                          <div className="mb-6">
                            <label
                              htmlFor="item-email"
                              className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                            >
                              Email<span className="text-red">*</span>
                            </label>
                            <input
                              type="email"
                              id="item-email"
                              name="email"
                              value={Email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                              placeholder="Enter Email"
                              required
                            />
                          </div>
                          <div className="form_betwine flex justify-end pt-2">
                            <Link
                              className="inline-block rounded bg-accent py-2 px-5 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                              onClick={nextAction}
                            >
                              <i className="ri-user-add-line text-lg"></i>
                              <span>Add recipients</span>
                            </Link>
                          </div>
                        </> : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="fixed bottom-0 left-0 w-full dark:bg-jacarta-700 p-4">
            {checkedref.current == true ?
              <div className="container-lg mx-auto flex justify-end gap_cls">
                <button
                  className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  onClick={() => nextAction("/digitalDash")}
                >
                  Back
                </button>
                <button
                  className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  onClick={nextAction}
                >
                  Next
                </button>
              </div> : ""}
          </footer>
        </> :
        <>
          <div className="flex min-h-screen bg-[#0D0B35] header_cls">
            {width >= 768 ? <DesktopSidebar /> : <MobileBottomNav />}
            <main className="ml-64 w-full bg-[#0D0B35] p-8 side_content">
              <div className="container mx-auto h-[100vh] overflow-y-auto bg-[#10153D] rounded-lg p-6 relative" ref={pdfViewerRef}>
                <Worker workerUrl={pdfWorker}>
                  <Viewer
                    fileUrl={pdfFile}
                  />
                </Worker>
                {renderElements()}
              </div>

            </main>
            <footer className="fixed bottom-0 left-0 w-full dark:bg-jacarta-700 p-4 footer_respon">
              <div className="container-lg mx-auto flex justify-end gap_cls">
                <button
                  onClick={cancel}
                  className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Cancel
                </button>
                {loader == true ?
                  <button

                    className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Loading...
                  </button>
                  :
                  <button
                    onClick={saveToPDF}
                    className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Done
                  </button>
                }
              </div>
            </footer>

            {/* Upload Modal */}
            <div
              className="modal fade"
              id="upload"
              tabIndex="-1"
              aria-labelledby="walletModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered max-w-lg justify-center">
                <div className="modal-content max-w-lg width_modlew_60">
                  <div className="modal-header model_padding">
                    <h5 className="modal-title" id="walletModalLabel">
                      Upload Your Sign
                    </h5>
                    <button
                      type="button"
                      className="btn-close flex_close_button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                      </svg>
                    </button>
                  </div>

                  <div className="modal-body p-6 text-center">
                    <div className="flex items-center justify-center min-h-screen bg-[#0D0B35]">
                      <label
                        htmlFor="file-upload"
                        className="w-full bg-[#10153D] p-12 rounded-lg border-dashed border-2 border-gray-600 cursor-pointer flex flex-col items-center justify-center text-center module_filw"
                      >
                        <i className="ri-upload-cloud-line text-white text-4xl mb-4"></i>
                        <span className="text-white text-lg font-medium" onClick={() => fileInputRef.current.click()}>
                          Upload Signature
                        </span>
                        <input id="file-upload" onChange={handleUploadSignature} type="file" className="hidden" />
                      </label>
                    </div>
                  </div>

                  <div className="modal-footer flex items-end justify-end py-10 model_padding">
                    <div className="flex items-end justify-end space-x-4 flex_end_sner">
                      <button
                        onClick={saveUploadedSignature}
                        className="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Sign
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Draw Signature Modal */}
            <div
              className="modal fade"
              id="Draw"
              tabIndex="-1"
              aria-labelledby="DrawSignLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered max-w-lg justify-center">
                <div className="modal-content max-w-lg width_modlew_60">
                  <div className="modal-header model_padding">
                    <h5 className="modal-title" id="DrawSignLabel">
                      Draw Your Sign
                    </h5>
                    <button
                      type="button"
                      className="btn-close flex_close_button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                      </svg>
                    </button>
                  </div>
                  <div className="modal-body p-6 text-center">
                    <SignatureCanvas
                      penColor="black"
                      ref={signatureRef}
                      canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
                    />
                  </div>
                  <div className="modal-footer flex items-end justify-end py-10 model_padding">
                    <div className="flex items-end justify-end space-x-4 flex_end_sner">
                     
                      <button
                        onClick={clearSignature}
                        className="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Clear
                      </button>
                      <button
                        onClick={saveSignature}
                        className="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Sign
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Type Signature Modal */}
            <div
              className="modal fade"
              id="TextSign"
              tabIndex="-1"
              aria-labelledby="TextSignLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered max-w-lg justify-center">
                <div className="modal-content max-w-lg width_modlew_60">
                  <div className="modal-header model_padding">
                    <h5 className="modal-title" id="TextSignLabel">
                      Type Your Sign
                    </h5>
                    <button
                      type="button"
                      className="btn-close flex_close_button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                      </svg>
                    </button>
                  </div>
                  <div className="modal-body p-6 text-center">
                    <div className="text-white">
                      <input
                        type="text"
                        value={typedSignature}
                        placeholder="Type your signature"
                        onChange={(e) => setTypedSignature(e.target.value)}
                        className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      />
                    </div>
                    <div className="mt-4">
                      <select
                        onChange={handleFontChange} value={selectedFont}
                        className="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                      >
                        {fonts.map((font, index) => (
                          <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto" key={index} value={font}>
                            {font}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer flex items-end justify-end py-10 model_padding">
                    <div className="flex items-end justify-end space-x-4 flex_end_sner">
                      <button
                        onClick={addText}
                        className="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Sign
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
    </>
  );
}

export default NewLayout;
