import React, { useEffect, useState } from "react";
// import Sliderimg from "./Sliderimg";
// import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Headers from "../../pages/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function Home() {

  const navigate = useNavigate()
  // const apiurl = "http://localhost:5001/"
  const apiurl = "https://market.lawblocks.io:5001/"
  const [signedPdfs, setSignedPdfs] = useState([]);
  const [sharedPdf, setsharedPdf] = useState([]);
  const [completed, setcompleted] = useState([]);
  const [userDetails, setuserDetails] = useState({})


  useEffect(() => {
    fetchSignedPdfs();
    getSharedpdf();
    getuser()
  }, [])

  const getuser = async () => {
    const response = await axios.get(apiurl + 'api/getuser');
    if (response.data.status == true) {
      setuserDetails(response.data.data)
    } else {
      setuserDetails(response.data.data)
    }
  }

  const fetchSignedPdfs = async () => {
    try {
      const response = await axios.get(apiurl + 'api/signed-pdfs');
      setSignedPdfs(response.data.data);
      var arr = []
      for (let i = 0; i < response.data.data.length; i++) {
        const element = response.data.data[i];
        if (element.status == 1) {
          arr.push(element)
        }
      }
      setcompleted(arr)
    } catch (error) {
      console.error('Error fetching signed PDFs:', error);
    }
  };

  const getSharedpdf = async () => {
    try {
      const response = await axios.get(apiurl + 'api/getsharedPdf');
      setsharedPdf(response.data.data);
    } catch (error) {
      console.error('Error fetching signed PDFs:', error);
    }
  };

  const Navigate = (value) => {
    navigate(value)
  }


  return (
    <>
      <Headers />
      <section class="relative overflow-hidden">
        <picture class="pointer-events-none absolute inset-0 -z-10 h-20">
          <img
            src={require("../../img/newbannar/newbannar/banner_bg.png")}
            alt="gradient"
            class="w-full"
          />
        </picture>
        <div class="container pt-20">
          <section class="relative pt-16  pb-8">
            <div class="container">
              <div class="flex flex-wrap">
                <div class="w-full lg:w-1/3 flex flex-col justify-end">
                  <h1 class="text-left font-display text-4xl font-medium text-jacarta-700 dark:text-white">
                    Welcome
                  </h1>
                  <div class="profile-container mt-4">
                    <div class="profile-avatar bg-gray-300 rounded-full h-12 w-12 flex items-center justify-center text-xl font-bold ">
                      UN
                    </div>
                    <div class="profile-details ml-4">
                      <div class="name text-lg font-medium text-jacarta-700 dark:text-white">
                        {userDetails.fullName}
                      </div>
                      <div class="text-sm dark:text-jacarta-300">
                        {userDetails.email}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-2/3">
                  
                  <div class="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                    <button onClick={() => Navigate('/Digitaldetails')} class="block text-center">
                      <h1 class="mb-1 mt-2 font-display text-5xl lg:text-6xl text-jacarta-700 dark:text-white text-left">
                        {sharedPdf.length}
                      </h1>
                      <p class="text-md leading-normal text-jacarta-700 dark:text-white text-left">
                        Action Required
                      </p>
                    </button>
                    <button onClick={() => Navigate('/Digitaldetails')} class="block text-center">
                      <h1 class="mb-1 mt-2 font-display text-5xl lg:text-6xl text-jacarta-700 dark:text-white text-left">
                        {signedPdfs.length}
                      </h1>
                      <p class="text-md leading-normal text-jacarta-700 dark:text-white text-left">
                        Waiting For Others
                      </p>
                    </button>
                    <button onClick={() => Navigate('/Digitaldetails')} class="block text-center">
                      <h1 class="mb-1 mt-2 font-display text-5xl lg:text-6xl text-jacarta-700 dark:text-white text-left">
                        {completed.length}
                      </h1>
                      <p class="text-md leading-normal text-jacarta-700 dark:text-white text-left">
                        Completed
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section className="py-10">
        <div className="container">
          <h2 class="text-left text-white text-3xl font-semibold mb-8">
            Get Started
          </h2>
          <div class="bg-[#0D0B35] min-h-screen flex items-center justify-center  w-full">
            <div class="w-full  bg-[#10153D] p-8 rounded-lg border-dashed border-2 border-gray-600 upload_section_code">
              <div class="flex flex-col items-center justify-center text-center">
                <h3 class="text-left text-white text-2xl font-semibold mb-3">
                  Sign Your Document
                </h3>
                <button
                  onClick={() => Navigate('/digital_sign')}
                  class="bg-accent hover:bg-accent-dark text-white px-6 py-3 rounded-lg font-semibold hover:bg-[#6c41d6] transition"
                >
                  Start Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
}

export default Home;
