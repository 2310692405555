import React, { useState, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import SignatureCanvas from 'react-signature-canvas';
import { Rnd } from 'react-rnd';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function PdfSignature() {
  const [pdfFile, setPdfFile] = useState(null);
  const [elements, setElements] = useState([]);
  const [isSigning, setIsSigning] = useState(false);
  const [isAddingText, setIsAddingText] = useState(false);
  const [uploadedSignature, setUploadedSignature] = useState(null);
  const [showTools, setShowTools] = useState(true); // State to control tool visibility
  const signatureRef = useRef({});
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const pdfViewerRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdfFile(URL.createObjectURL(file));
    } else {
      alert('Please select a PDF file.');
    }
  };

  const startSignature = () => {
    setIsSigning(true);
  };

  const startAddingText = () => {
    setIsAddingText(true);
  };

  const saveSignature = () => {
    const signatureImage = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    setElements([...elements, { type: 'signature', src: signatureImage, x: 50, y: 50, width: 200, height: 100 }]);
    setIsSigning(false);
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveUploadedSignature = () => {
    setElements([...elements, { type: 'signature', src: uploadedSignature, x: 50, y: 50, width: 200, height: 100 }]);
    setUploadedSignature(null);
  };

  const addText = () => {
    setElements([...elements, { type: 'text', text: 'Your Text Here', x: 50, y: 50, width: 200, height: 50 }]);
    setIsAddingText(false);
  };

  const updateElementPosition = (index, x, y, width, height) => {
    const newElements = [...elements];
    newElements[index] = { ...newElements[index], x, y, width, height };
    setElements(newElements);
  };

  const handleUploadSignature = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setUploadedSignature(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please upload an image file.');
    }
  };

  const renderElements = () => {
    return elements.map((element, index) => (
      <Rnd
        key={index}
        size={{ width: element.width, height: element.height }}
        position={{ x: element.x, y: element.y }}
        onDragStop={(e, d) => updateElementPosition(index, d.x, d.y, element.width, element.height)}
        onResizeStop={(e, direction, ref, delta, position) => {
          updateElementPosition(index, position.x, position.y, parseInt(ref.style.width), parseInt(ref.style.height));
        }}
        style={{ border: '1px solid #3f51b5', padding: '4px' }}  // Highlight the resizing points
      >
        {element.type === 'signature' ? (
          <img
            src={element.src}
            alt={`signature-${index}`}
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <div style={{ width: '100%', height: '100%', fontSize: '16px', padding: '4px' }}>
            {element.text}
          </div>
        )}
      </Rnd>
    ));
  };

  const saveToPDF = async () => {
    setShowTools(false);  // Hide the tools before saving
    const pdfViewerElement = pdfViewerRef.current;
    const canvas = await html2canvas(pdfViewerElement);
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
    const pdfBlob = pdf.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);

    setPdfFile(pdfUrl);  // Update the state with the newly created PDF URL
    setShowTools(true);   // Optionally, show the tools again after saving
  };

  return (
    <div>
      {showTools && <input type="file" onChange={handleFileChange} />}
      {pdfFile && (
        <div style={{ position: 'relative', marginTop: '20px' }} ref={pdfViewerRef}>
          <Worker workerUrl={`${window.location.origin}/pdf.worker.min.js`}>
            <Viewer
              fileUrl={pdfFile}
            />
          </Worker>
          {renderElements()}
        </div>
      )}

      {isSigning && (
        <div>
          <SignatureCanvas
            penColor="black"
            ref={signatureRef}
            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          />
          <button onClick={saveSignature}>Save Signature</button>
          <button onClick={clearSignature}>Clear</button>
        </div>
      )}

      {!isSigning && !isAddingText && pdfFile && showTools && (
        <div>
          <button onClick={startSignature}>Add Signature</button>
          <button onClick={startAddingText}>Add Text</button>
          <input type="file" onChange={handleUploadSignature} />
          {uploadedSignature && <button onClick={saveUploadedSignature}>Save Uploaded Signature</button>}
          <button onClick={saveToPDF}>Save Document</button>
        </div>
      )}

      {isAddingText && (
        <div>
          <button onClick={addText}>Add Text</button>
        </div>
      )}
    </div>
  );
}

export default PdfSignature;
