import React from "react";

function DesktopSidebar({activeTab, setActiveTab}) {
  return (
    <aside className="width_60 dark:bg-jacarta-700 fixed left-0 top-0 h-full flex flex-col py-8 px-4 space-y-8 " style={{ marginTop : "98px"}}>
      <div className="flex flex-col space-y-6">
        {/* <button
          className={`flex items-center space-x-2 text-white hover:text-accent transition nav-link relative flex items-center whitespace-nowrap py-3 px-0 tab_hover ${
            activeTab === "Inbox"
              ? "text-jacarta-700 dark:text-white tab_hover-aactive"
              : "text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
          }`}
          onClick={() => setActiveTab("Inbox")}
        >
          <i className="ri-mail-line text-2xl"></i>
          <span className="text-lg">Inbox</span>
        </button> */}
        <button
          className={`flex items-center space-x-2 text-white hover:text-accent transition nav-link relative flex items-center whitespace-nowrap py-3 px-0 tab_hover ${
            activeTab === "Sent"
              ? "text-jacarta-700 dark:text-white tab_hover-aactive"
              : "text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
          }`}
          onClick={() => setActiveTab("Sent")}
        >
          <i className="ri-send-plane-line text-2xl"></i>
          <span className="text-lg">Sent</span>
        </button>
        <button
          className={`flex items-center space-x-2 text-white hover:text-accent transition nav-link relative flex items-center whitespace-nowrap py-3 px-0 tab_hover ${
            activeTab === "Completed"
              ? "text-jacarta-700 dark:text-white tab_hover-aactive"
              : "text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
          }`}
          onClick={() => setActiveTab("Completed")}
        >
          <i className="ri-checkbox-circle-line text-2xl"></i>
          <span className="text-lg">Completed</span>
        </button>
        <button
          className={`flex items-center space-x-2 text-white hover:text-accent transition nav-link relative flex items-center whitespace-nowrap py-3 px-0 tab_hover  ${
            activeTab === "ActionRequired"
              ? "text-jacarta-700 dark:text-white tab_hover-aactive"
              : "text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
          }`}
          onClick={() => setActiveTab("ActionRequired")}
        >
          <i className="ri-pencil-line text-2xl"></i>
          <span className="text-lg">Action Required</span>
        </button>
      </div>
    </aside>
  );
}

export default DesktopSidebar;
