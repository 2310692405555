import React from "react";

function MobileBottomNav({activeTab, setActiveTab}) {
  return (
    <nav className="dark:bg-jacarta-700 fixed bottom-0 left-0 w-full bg-[#0D0B35] flex justify-around py-4 space_around_section">
      {/* <button
        className={`flex flex-col items-center text-white hover:text-accent ${
          activeTab === "Inbox"
            ? "text-jacarta-700 dark:text-white tab_hover-active"
            : "text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
        }`}
        onClick={() => setActiveTab("Inbox")}
      >
        <i className="ri-mail-line text-2xl"></i> 
        <span className="text-lg font_14pxs">Inbox</span>
      </button> */}
      <button
        className={`flex flex-col items-center text-white hover:text-accent ${
          activeTab === "Sent"
            ? "text-jacarta-700 dark:text-white tab_hover-active"
            : "text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
        }`}
        onClick={() => setActiveTab("Sent")}
      >
        <i className="ri-send-plane-line text-2xl"></i> {/* Sent Icon */}
        <span className="text-lg font_14pxs">Sent</span>
      </button>
      <button
        className={`flex flex-col items-center text-white hover:text-accent ${
          activeTab === "Completed"
            ? "text-jacarta-700 dark:text-white tab_hover-active"
            : "text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
        }`}
        onClick={() => setActiveTab("Completed")}
      >
        <i className="ri-checkbox-circle-line text-2xl"></i>{" "}
        {/* Completed Icon */}
        <span className="text-lg font_14pxs">Completed</span>
      </button>
      <button
        className={`flex flex-col items-center text-white hover:text-accent ${
          activeTab === "ActionRequired"
            ? "text-jacarta-700 dark:text-white tab_hover-active"
            : "text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
        }`}
        onClick={() => setActiveTab("ActionRequired")}
      >
        <i className="ri-pencil-line text-2xl"></i> {/* Action Required Icon */}
        <span className="text-lg font_14pxs">Action Required</span>
      </button>
    </nav>
  );
}

export default MobileBottomNav;
