import React, {useState, useEffect} from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import {getMethod} from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

import {Country, State, City} from "country-state-city";
import Select from "react-select";

function TemplateMoreFiter() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const [allAdvocate, setallAdvocate] = useState([]);
  const [lawLoader, setlawLoader] = useState(false);
  const [templateDetails, settemplateDetails] = useState([]);

  const [categoryDetails, setCategory] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectCategories, setselectCategory] = useState("");

  const [slashCountry, setslashCountry] = useState("All");
  const [slashCities, setslashCities] = useState("");
  const [slashStates, setslashStates] = useState("");
  const [slashCategory, setslashCategory] = useState("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // getLawerDetails();
    getAllTemplateDetails();
  }, [0]);

  const getLawerDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.getAllAdvocates,
      };
      setlawLoader(true);
      var resp = await getMethod(data);
      setlawLoader(false);
      if (resp.status == true) {
        setallAdvocate(resp.Message);
      } else {
        setallAdvocate([]);
      }
    } catch (error) {
    }
  };

  const goToProfile = async (id) => {
    try {
      navigate("/profile?" + id);
    } catch (error) {
    }
  };

  const getAllTemplateDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.templateDetails,
      };
      setlawLoader(true);
      var resp = await getMethod(data);
      setlawLoader(false);
      if (resp.status == true) {
        settemplateDetails(resp.Message);
        var carArray = [];
        for (var i = 0; i < resp.Message.length; i++) {
          var obj = {
            value: i + 1,
            label: resp.Message[i].category,
          };
          carArray.push(obj);
        }
        setCategory(carArray);
      } else {
        settemplateDetails([]);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    // setCurrency(state);
    const getCountries = async () => {
      try {
        setIsLoading(true);
        const result = await Country.getAllCountries();

        let allCountries = [];
        allCountries = result?.map(({isoCode, name}) => ({
          isoCode,
          name,
        }));
        const [{name: firstCountry} = {}] = allCountries;
        setCountries(allCountries);
        setSelectedCountry(firstCountry);
        setIsLoading(false);
      } catch (error) {
        setCountries([]);
        setIsLoading(false);
      }
    };

    getCountries();
  }, []);

  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({isoCode, name}) => ({
          isoCode,
          name,
        }));
        const [{isoCode: firstState = ""} = {}] = allStates;
        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(firstState);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };

    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(
          selectedCountry,
          selectedState
        );
        let allCities = [];
        allCities = result?.map(({name}) => ({
          name,
        }));
        const [{name: firstCity = ""} = {}] = allCities;
        setCities(allCities);
        setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };

    getCities();
  }, [selectedState]);

  const focus = async () => {
  };

  const focusLL = async () => {
  };
  const search = async (value) => {
    if (selectedCountry) {
      if (value == "country") {
        var obj = {
          country: selectedCountry,
          category: "",
          status: "",
        };
      }
      if (value == "state") {
        var obj = {
          country: selectedCountry,
          state: selectedState,
          category: "",
          status: "",
        };
      }
      setslashCountry(selectedCountry);
      setslashCities(selectedCity);
      setslashStates(selectedState);

      var data = {
        apiUrl: apiService.searching,
        payload: obj,
      };
      var resp = await postMethod(data);

      if (resp.status == true) {
        settemplateDetails(resp.Message.getcountryDocuments);
        // toast(resp.Message);
      }
    } else {
    }
  };

  const selectCategory = async (data) => {
  };

  const handleChange = async (e) => {
    setSelectedOption(e);
    setselectCategory(e.label);
    var obj = {
      country: selectedCountry,
      city: selectedCity,
      state: selectedState,
      category: e.label,
      status: "",
    };
    setslashCategory(e.label);
    var data = {
      apiUrl: apiService.searching,
      payload: obj,
    };
    setlawLoader(true);
    var resp = await postMethod(data);
    setlawLoader(false);
    if (resp.status == true) {
      settemplateDetails(resp.Message.getcountryDocuments);
      // toast(resp.Message);
    }
  };
  const clearALL = async () => {
    getAllTemplateDetails();
  };

  // getAllAdvocates

  return (
    <>
      <Header />
      <main class="mt-24">
        <section class="relative pt-16 pb-24">
          <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img
              src={require("../img/gradient_light.jpg")}
              alt="gradient"
              class="h-full w-full"
            />
          </picture>
          <div class="px-6 xl:px-24">
            <div class="flex flex-wrap justify-between">
              <div class="flex space-x-2 mb-2">
                <button class="js-collections-toggle-filters flex h-10 group flex-shrink-0 items-center justify-center space-x-1 rounded-lg border border-jacarta-100 bg-white py-1.5 px-4 font-display text-sm font-semibold text-jacarta-500 hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="h-4 w-4 fill-jacarta-700 dark:fill-white group-hover:fill-white"
                  >
                    <path fill="none" d="M0 0H24V24H0z"></path>
                    <path d="M21 4v2h-1l-5 7.5V22H9v-8.5L4 6H3V4h18zM6.404 6L11 12.894V20h2v-7.106L17.596 6H6.404z"></path>
                  </svg>
                  <span class="mt-0.5 dark:text-white group-hover:text-white">
                    Filters
                  </span>
                </button>

                <button
                  onClick={clearALL}
                  class="lex h-10 group flex-shrink-0 items-center justify-center space-x-1 rounded-lg border border-jacarta-100 bg-white py-1.5 px-4 font-medium text-2xs hover:bg-accent hover:border-accent dark:hover:bg-accent dark:border-jacarta-600 dark:bg-jacarta-700"
                >
                  <span class="mt-0.5 dark:text-white group-hover:text-white">
                    Clear All
                  </span>
                </button>
              </div>
            </div>

            <div class="lg:flex mt-6">
              <div class="lg:w-1/5 mb-10 js-collections-sidebar lg:h-[calc(100vh_-_232px)] lg:overflow-auto lg:sticky lg:top-32 lg:mr-12 pr-4 scrollbar-custom divide-y divide-jacarta-100 dark:divide-jacarta-600 margin_pading_left-0">
                <div>
                  <h2 id="filters-collections-heading">
                    <button
                      class="accordion-button relative flex w-full items-center justify-between py-3 text-left font-display text-xl text-jacarta-700 dark:text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#filters-collections"
                      aria-expanded="true"
                      aria-controls="filters-collections"
                    >
                      <span>Location</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="accordion-arrow h-5 w-5 shrink-0 fill-jacarta-700 transition-transform dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                      </svg>
                    </button>
                  </h2>
                  <div
                    id="filters-collections"
                    class="mt-3 collapse show"
                    aria-labelledby="filters-collections-heading"
                  >
                    <div className="mb-6">
                      <div>
                        <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
                          Country
                        </label>
                      </div>

                      <div class="dropdown my-1 cursor-pointer search_dev">
                        <select
                          class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                          id="cars"
                          name="country"
                          value={selectedCountry}
                          onBlur={focus}
                          onFocus={focusLL}
                          onChange={(event) =>
                            setSelectedCountry(event.target.value)
                          }
                        >
                          {countries.length > 0 ? (
                            countries.map(({isoCode, name}) => (
                              <option
                                onBlur={focus}
                                class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                value={isoCode}
                                key={isoCode}
                              >
                                <span>{name}</span>
                                {/* {name} */}
                              </option>
                            ))
                          ) : (
                            <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                              No Countries Found!
                            </option>
                          )}
                        </select>
                        <button
                          onClick={() => search("country")}
                          style={{cursor: "pointer"}}
                          class="cursor-default rounded-full bg-accent-lighter py-3 px-8 text-center font-semibold text-white transition-all search_butn"
                        >
                          <img
                            src={require("../img//searcc.png")}
                            class="icon_width"
                            alt="Xhibiter | NFT Marketplace"
                          />
                        </button>

                        {/* {
                 countryError == true  ?  <p  class="text-red" >Country is required!</p> : ""
               } */}
                      </div>
                    </div>
                    {/* <div className="mb-6">
                      <div>
                        <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
                          State
                        </label>
                      </div>
                      <div class="dropdown my-1 cursor-pointer search_dev">
                        <select
                          class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                          name="state"
                          value={selectedState}
                          onChange={(event) =>
                            setSelectedState(event.target.value)
                          }
                        >
                          {states.length > 0 ? (
                            states.map(({isoCode, name}) => (
                              <option
                                class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                value={isoCode}
                                key={isoCode}
                              >
                                {name}
                              </option>
                            ))
                          ) : (
                            <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                              No States Found!
                            </option>
                          )}
                        </select>
                        <button
                          onClick={() => search("state")}
                          style={{cursor: "pointer"}}
                          class="cursor-default rounded-full bg-accent-lighter py-3 px-8 text-center font-semibold text-white transition-all search_butn"
                        >
                          <img
                            src={require("../img//searcc.png")}
                            class="icon_width"
                            alt="Xhibiter | NFT Marketplace"
                          />
                        </button>
                      </div>
                    </div> */}
                    <button onClick={() => search("state")} className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">Search</button>
                  </div>
                </div>

                {/* <div class="mt-4 pt-4">
                  <h2 id="filters-chains-heading">
                    <button
                      class="accordion-button collapsed relative flex w-full items-center justify-between py-3 text-left font-display text-xl text-jacarta-700 dark:text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#filters-chains"
                      aria-expanded="false"
                      aria-controls="filters-chains"
                    >
                      <span>Categories</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="accordion-arrow h-5 w-5 shrink-0 fill-jacarta-700 transition-transform dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                      </svg>
                    </button>
                  </h2>
                  <div
                    id="filters-chains"
                    class="mt-3 collapse"
                    aria-labelledby="filters-chains-heading"
                  >
                    <div className="mb-6">
                      <div>
                        <label className="mb-2 block font-display text-jacarta-700 dark:text-white">
                          Select Categories
                        </label>
                      </div>

                      <Select
                        placeholder="Select Category"
                        value={selectedOption} // set selected value
                        options={categoryDetails} // set list of the data
                        onChange={handleChange} // assign onChange function
                      />

                      {/* 
                      <div class="dropdown relative cursor-pointer" >
                        <div
                          class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white w-full h-12 py-3 px-4 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"
                          role="button"
                          id="filtersPrice"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                         
                        >
                             
                        
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-5 w-5 fill-jacarta-500 dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                          </svg>
                        </div>

                        <div
                          class="dropdown-menu z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800"
                          aria-labelledby="filtersPrice"
                        >
                          <button class="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm text-jacarta-700 transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                            USD
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-accent"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                            </svg>
                          </button>
                          <button class="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                            ETH
                          </button>
                          <button class="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                            SOL
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div class="mt-4 pt-4">
                  <h2 id="filters-status-heading">
                    <button
                      class="accordion-button collapsed relative flex w-full items-center justify-between py-3 text-left font-display text-xl text-jacarta-700 dark:text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#filters-status"
                      aria-expanded="false"
                      aria-controls="filters-status"
                    >
                      <span>Status</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="accordion-arrow h-5 w-5 shrink-0 fill-jacarta-700 transition-transform dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                      </svg>
                    </button>
                  </h2>
                  <div
                    id="filters-status"
                    class="mt-3 collapse"
                    aria-labelledby="filters-status-heading"
                  >
                    <ul class="space-y-6 mb-8">
                      <li>
                        <label class="flex items-center cursor-pointer w-full">
                          <input
                            type="checkbox"
                            id="terms"
                            class="h-5 w-5 mr-2 rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600"
                          />
                          <span class="dark:text-white">Paid</span>
                        </label>
                      </li>
                      <li>
                        <label class="flex items-center cursor-pointer w-full">
                          <input
                            type="checkbox"
                            id="terms"
                            class="h-5 w-5 mr-2 rounded border-jacarta-200 text-accent checked:bg-accent focus:ring-accent/20 focus:ring-offset-0 dark:border-jacarta-500 dark:bg-jacarta-600"
                          />
                          <span class="dark:text-white">Free</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div> */}

                {/* <div class="mt-4 pt-4">
                  <h2 id="filters-price-heading">
                    <button
                      class="accordion-button relative flex w-full items-center justify-between py-3 text-left font-display text-xl text-jacarta-700 dark:text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#filters-price"
                      aria-expanded="true"
                      aria-controls="filters-price"
                    >
                      <span>Price</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="accordion-arrow h-5 w-5 shrink-0 fill-jacarta-700 transition-transform dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                      </svg>
                    </button>
                  </h2>
                  <div
                    id="filters-price"
                    class="mt-3 mb-8 space-y-4 collapse show"
                    aria-labelledby="filters-price-heading"
                  >
                    <div class="dropdown relative cursor-pointer">
                      <div
                        class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white w-full h-12 py-3 px-4 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white"
                        role="button"
                        id="filtersPrice"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>USD</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="h-5 w-5 fill-jacarta-500 dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                        </svg>
                      </div>

                      <div
                        class="dropdown-menu z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800"
                        aria-labelledby="filtersPrice"
                      >
                        <button class="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm text-jacarta-700 transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                          USD
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-accent"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                          </svg>
                        </button>
                        <button class="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                          ETH
                        </button>
                        <button class="dropdown-item flex w-full items-center justify-between rounded-xl px-5 py-2 text-left font-display text-sm transition-colors hover:bg-jacarta-50 dark:text-white dark:hover:bg-jacarta-600">
                          SOL
                        </button>
                      </div>
                    </div>

                    <div class="flex space-x-4">
                      <input
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        type="text"
                        placeholder="Min"
                      />
                      <input
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        type="text"
                        placeholder="Max"
                      />
                    </div>

                    <button
                      type="submit"
                      class="rounded-full bg-accent-lighter w-full py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark"
                    >
                      Apply
                    </button>
                  </div>
                </div>

                <div class="mt-4 pt-4">
                  <h2 id="filters-categories-heading">
                    <button
                      class="accordion-button relative flex w-full items-center justify-between py-3 text-left font-display text-xl text-jacarta-700 dark:text-white"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#filters-categories"
                      aria-expanded="true"
                      aria-controls="filters-categories"
                    >
                      <span>Categories</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="accordion-arrow h-5 w-5 shrink-0 fill-jacarta-700 transition-transform dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                      </svg>
                    </button>
                  </h2>
                  <div
                    id="filters-categories"
                    class="mt-3 collapse show"
                    aria-labelledby="filters-categories-heading"
                  >
                    <ul class="flex flex-wrap items-center">
                      <li class="my-1 mr-2.5">
                        <button class="group flex h-9 items-center rounded-lg border border-accent bg-accent px-4 font-display text-sm font-semibold text-white transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white">
                          <span>Art</span>
                        </button>
                      </li>
                      <li class="my-1 mr-2.5">
                        <button class="group flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white">
                          <span>Collectibles</span>
                        </button>
                      </li>
                      <li class="my-1 mr-2.5">
                        <button class="group flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white">
                          <span>Domain</span>
                        </button>
                      </li>
                      <li class="my-1 mr-2.5">
                        <button class="group flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white">
                          <span>Music</span>
                        </button>
                      </li>
                      <li class="my-1 mr-2.5">
                        <button class="group flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white">
                          <span>Photography</span>
                        </button>
                      </li>
                      <li class="my-1 mr-2.5">
                        <button class="group flex h-9 items-center rounded-lg border border-jacarta-100 bg-white px-4 font-display text-sm font-semibold text-jacarta-500 transition-colors hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-900 dark:text-white dark:hover:border-transparent dark:hover:bg-accent dark:hover:text-white">
                          <span>Virtual World</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>

              <div class="lg:w-4/5 js-collections-content">
                <div class="mb-8 pb-px">
                  <h1 class="pt-3 mb-2 font-display text-2xl font-medium text-jacarta-700 dark:text-white">
                    Legal Documents{" "}
                    {slashCountry == "" ? "" : " / " + slashCountry}{" "}
                    {slashStates == "" ? "" : " / " + slashStates}{" "}
                    {slashCities == "" ? "" : " / " + slashCities}{" "}
                    {slashCategory == "" ? "" : " / " + slashCategory}
                  </h1>
                  <p class="dark:text-jacarta-400 font-medium text-2xs">
                    {templateDetails.length} items
                  </p>
                </div>

                <div class="tab-content">
                  <div
                    class="tab-pane fade show active"
                    id="view-grid"
                    role="tabpanel"
                    aria-labelledby="view-grid-tab"
                  >
                    {lawLoader == true ? (
                      <div class="flex_loading">
                        <img
                          width="60"
                          height="60"
                          class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                          src={require("../img/loadinggif.gif")}
                        ></img>
                      </div>
                    ) : templateDetails.length > 0 ? (
                      <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-3 js-collections-grid">
                        {templateDetails.map((item, i) => {
                          return (
                            <div class="">
                              <article>
                                <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
                                  <a href={"/Item?" + item._id}>
                                    <div className="pfd_view height_new">
                                      <div className="image_vie">
                                        {/* <img
                                       src={require("../img/darklogo.png")}
                                       class="hidden max-h-7 dark:block"
                                       alt="Xhibiter | NFT Marketplace"
                                     /> */}
                                      </div>
                                      <div className="pdf_title">
                                        {/* <p>Title</p> */}
                                        <h4 className="titel_nme">
                                          {" "}
                                          {item && item.name}{" "}
                                        </h4>
                                      </div>
                                      <div className="pdf_title">
                                        
                                      {/* <p>Description</p> */}
                                      <h4 className="text_desctpon">
                                        { item &&  item.description}
                                      </h4>
                                    </div>

                                      <div className="pdf_auther">
                                        <h4> Advocate Name </h4>

                                        <p>{item && item?.user_id?.fullName} </p>
                                      </div>
                                    </div>
                                  </a>
                                                                  
                                <div class="mt-8 flex items-center justify-between">
                               
                                  <div class="mt-2 text-sm">
                                      <span class="dark:text-jacarta-300">{item && item.state}, {" "} { item && item.country} </span>
                                      <br />
                                      <span class="text-jacarta-700 dark:text-jacarta-100">
                                        Price { item && item.amount} {" "} { item && item.currency} 
                                      </span>
                                    </div>

                                  <span class="flex items-center whitespace-nowrap rounded-md border border-jacarta-100 py-1 px-2 dark:border-jacarta-600">
                                          
                                          {
                                          item &&  item?.user_id?.prfileIMG == "" ? 
                                          <img
                                          src={require("../img/tharasu.png")}
                                          alt="gradient"
                                          class="h-full w-full width_img_icon"
                                        /> : 
                                        <img
                                        src={item?.user_id?.prfileIMG}
                                        alt="gradient"
                                        class="h-full w-full width_img_icon"
                                      />
                                          }
                                      </span>
                                </div>

                                  <div className="flex_cart">
                                    <a href={"/Item?" + item._id}>
                                      <button
                                        class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                                        aria-label="profile"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="bi bi-cart-check-fill h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white color_blsce"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                        </svg>
                                      </button>
                                    </a>
                                    <a href={"/Item?" + item._id}>
                                 <button
                                  class="font-display text-sm font-semibold text-accent"
                                  >
                                  Buy Now
                                  </button>
                                  </a>
                                  </div>
                                </div>
                              </article>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="notata_found">
                        <img
                          src={require("../img/file-searching/rafiki.png")}
                          class="widthds"
                          alt="Xhibiter | NFT Marketplace"
                        />
                        <p className="font-display text-base font-medium">
                          No Search Results Available!
                        </p>
                      </div>
                    )}
                  </div>

                  <div
                    class="tab-pane fade"
                    id="view-list"
                    role="tabpanel"
                    aria-labelledby="view-list-tab"
                  >
                    <div class="scrollbar-custom overflow-x-auto">
                      <div
                        role="table"
                        class="w-full min-w-[736px] border border-jacarta-100 bg-white text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white rounded-2lg"
                      >
                        <div
                          class="flex rounded-t-2lg bg-jacarta-50 dark:bg-jacarta-600"
                          role="row"
                        >
                          <div
                            class="md:w-2/5 w-1/4 py-3 px-4"
                            role="columnheader"
                          >
                            <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                              Collection
                            </span>
                          </div>
                          <div
                            class="md:w-[12%] w-[15%] py-3 px-4 text-right"
                            role="columnheader"
                          >
                            <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                              Floor Price
                            </span>
                          </div>
                          <div
                            class="md:w-[12%] w-[15%] py-3 px-4 text-right"
                            role="columnheader"
                          >
                            <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                              Volume
                            </span>
                          </div>
                          <div
                            class="md:w-[12%] w-[15%] py-3 px-4 text-right"
                            role="columnheader"
                          >
                            <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                              Volume Change
                            </span>
                          </div>
                          <div
                            class="md:w-[12%] w-[15%] py-3 px-4 text-right"
                            role="columnheader"
                          >
                            <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                              Items
                            </span>
                          </div>
                          <div
                            class="md:w-[12%] w-[15%] py-3 px-4 text-right"
                            role="columnheader"
                          >
                            <span class="w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100">
                              Owners
                            </span>
                          </div>
                        </div>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">1</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 1"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                              <div
                                class="absolute -right-2 -bottom-1 flex h-[1.125rem] w-[1.125rem] items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                                data-tippy-content="Verified Collection"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  class="h-[.875rem] w-[.875rem] fill-white"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                </svg>
                              </div>
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              NFT Funny Cat
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.12
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              3.265
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-red">-49.99%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            10.0K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            3.5K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">2</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 2"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Cryptopank
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.017
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              5.344
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+531.8%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            9.8K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            868
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">3</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 5"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Bored Bunny
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.39
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              12.503
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+384.2%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            3K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            1.2K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">4</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 9"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              NFT stars
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.002
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              6.098
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-red">-79.3%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            987
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            226
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">5</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 13"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Arcahorizons
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.05
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.149
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-red">-63.6%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            8.9K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            1.2K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">6</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 6"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Wow Frens
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.047
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.31
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+2,127.6%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            86
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            8.4K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">7</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 10"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                              <div
                                class="absolute -right-2 -bottom-1 flex h-[1.125rem] w-[1.125rem] items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                                data-tippy-content="Verified Collection"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  class="h-[.875rem] w-[.875rem] fill-white"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                </svg>
                              </div>
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Asumitsu
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.095
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              481.8
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+82.1%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            8.1K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            910
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">8</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 17"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                              <div
                                class="absolute -right-2 -bottom-1 flex h-[1.125rem] w-[1.125rem] items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                                data-tippy-content="Verified Collection"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  class="h-[.875rem] w-[.875rem] fill-white"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                </svg>
                              </div>
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Metasmorf
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              13.34
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              692.2
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+7.9%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            19.5K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            1.8K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">9</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 18"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              PankySkal
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.58
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              547.9
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-jacarta-400">—</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            3.5K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            2.1K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">10</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 19"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              The Overseers
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              63.5
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              1,607
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+47.5%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            100K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            33K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">11</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 20"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Dope Shibas
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              3.14
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              241.5
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+718.3%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            16K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            5.5K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">12</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 21"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              #1 Crush Editions
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              4.794
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              227
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+46.9%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            9.6K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            5.8K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">13</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 22"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Camp Cosmos
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              13.95
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              205.3
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-red">-44.3%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            10K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            5K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">14</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 23"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Land Of Whimsy
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              2.84
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              105.6
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-red">-53.8%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            10K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            3.3K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">15</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 24"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Audio Galleries
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.02
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              77.95
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+60.9%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            19.9K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            7.8K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">16</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 25"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                              <div
                                class="absolute -right-2 -bottom-1 flex h-[1.125rem] w-[1.125rem] items-center justify-center rounded-full border-2 border-white bg-green dark:border-jacarta-600"
                                data-tippy-content="Verified Collection"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  class="h-[.875rem] w-[.875rem] fill-white"
                                >
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                </svg>
                              </div>
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              Art Me Outside
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.047
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              91.76
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-red">-28.5%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            9.6K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            4.2K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">17</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 26"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              VR Space_287
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              0.015
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              72.86
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+73.61%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            4.3K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            9K
                          </div>
                        </a>
                        <a
                          href="user.html"
                          class="flex transition-shadow hover:shadow-lg"
                          role="row"
                        >
                          <div
                            class="flex md:w-2/5 w-1/4 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="mr-3 lg:mr-5">18</span>
                            <figure class="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                              <img
                                src={require("../img/avatars/avatar_1.jpg")}
                                alt="avatar 27"
                                class="rounded-2lg"
                                loading="lazy"
                              />
                            </figure>
                            <span class="font-display text-sm font-semibold text-jacarta-700 dark:text-white">
                              SlimHoods
                            </span>
                          </div>
                          <div
                            class="flex justify-end items-center md:w-[12%] w-[15%] whitespace-nowrap border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              1.739
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="-ml-1" data-tippy-content="ETH">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0"
                                y="0"
                                viewBox="0 0 1920 1920"
                                class="mr-1 h-4 w-4"
                              >
                                <path
                                  fill="#8A92B2"
                                  d="M959.8 80.7L420.1 976.3 959.8 731z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"
                                ></path>
                                <path
                                  fill="#454A75"
                                  d="M959.8 1295.4l539.8-319.1L959.8 731z"
                                ></path>
                                <path
                                  fill="#8A92B2"
                                  d="M420.1 1078.7l539.7 760.6v-441.7z"
                                ></path>
                                <path
                                  fill="#62688F"
                                  d="M959.8 1397.6v441.7l540.1-760.6z"
                                ></path>
                              </svg>
                            </span>
                            <span class="text-sm font-medium tracking-tight">
                              72.81
                            </span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            <span class="text-green">+151.3%</span>
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            9K
                          </div>
                          <div
                            class="flex justify-end md:w-[12%] w-[15%] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600"
                            role="cell"
                          >
                            3.7K
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default TemplateMoreFiter;
