import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "../src/css/style-rtl.css";
import "../src/css/style.css";
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import reportWebVitals from "./reportWebVitals";
pdfjs.GlobalWorkerOptions.workerSrc = `${window.location.origin}/pdf.worker.mi.js`;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
reportWebVitals();
